@import 'vars/colors';
@import 'vars/typography';
@import 'vars/environment';

html,
body {
	height: 100%;
}

body {
	margin: 0;
	background-color: $dkBlue;
	// background: linear-gradient(to bottom right, #7aede5, #52bbe9);
	background: $white;
	/* background: linear-gradient(
		to bottom right,
		rgb(21, 124, 209),
		rgb(54, 67, 85)
	); */
	background-attachment: fixed;
	@include uncollapse;

	&.no-scroll {
		overflow: hidden;
	}
}

#react-root {
	> div {
		@include uncollapse;
	}
}

a {
	color: inherit;
	text-decoration: none;
}

hr {
	border: none;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	margin: $space 0;
}

textarea {
	overflow-x: hidden;
}

*:focus {
	outline: 0;
}

.lit-link {
	@include link;
}

.lit-link-button {
	background: none;
	border: none;
	@include link;
}

.lit-no-button {
	background: none;
	border: none;
	color: inherit;
	font-size: inherit;
}
.lit-no-logout-button {
	background: none;
	border: none;
	color: inherit;
	font-size: inherit;
	align-items: center;
	margin-left: 13px;
	font-size: 15px;
	&__icon {
		margin-right: 8px;
	}
}
.lit-wrapper {
	@include wrapper(960);

	&--wide {
		@include wrapper(1140);
	}

	&--article {
		@include wrapper(680);
	}

	&--thin {
		@include wrapper($widthThin);
	}

	&--tiny {
		@include wrapper($inputWidth);
	}
}

.relative {
	position: relative;
}

.cl {
	cursor: pointer;
}

.normal {
	font-weight: 400;
}

.block {
	display: block;
}
