.lit-modal {
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	height: 100vh;
	width: 100%;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	z-index: $zModal;
	animation: fade 0.5s;

	&__island {
		box-shadow: $boxShadow4;
		background-color: $white;
		padding: $space;
		max-width: 960px;
		border: 5px solid $orange;
		margin: 50px auto;
		animation: slideUp 0.7s;
		border-radius: 25px;
		@include preventChildMargin;
	}
	&__other {
		box-shadow: $boxShadow4;
		background-color: $lightBlue;
		padding: $space;
		max-width: 676px;
		border: 5px solid $orange;
		margin: 50px auto;
		animation: slideUp 0.7s;
		border-radius: 25px;
		@include preventChildMargin;
	}
}
