@import 'vars/environment';
@import 'vars/mixins';
@import 'vars/breakpoints';
@import 'vars/typography';
@import 'vars/colors';

.lit-form {
	padding: $space $space;
	margin: $space-sm 0;

	&.inline {
		margin: $space-sm 0;
		padding: $space-sm $space;
	}
}

.lit-input {
	&__field {
		border: none;
		border-radius: $borderRadius;
		padding: $space-sm 7px;
		font-size: $p;
		display: block;
		font-family: $fontFamily;
		box-shadow: $boxShadow1;
		min-width: #{$inputWidth}px;
		box-sizing: border-box;

		&.disabled {
			background-color: #ccc;
		}
	}

	textarea {
		min-height: 150px;
	}
}

.lit-input {
	margin: $space-xsm 0;
	text-align: left;

	&__label {
		display: block;
		margin-bottom: $space-xsm;
		font-size: $p;

		&--count {
			display: inline-block;
			font-size: $h3;
			width: 54px;
			height: 54px;
			border-radius: 50%;
			border: 2px solid;
			text-align: center;
			line-height: 50px;
			box-sizing: border-box;
			opacity: 0.8;
			flex-shrink: 0;
		}
	}

	&__field {
		min-width: 0;
		width: 100%;

		&:focus {
			// outline: 4px solid rgba(0,0,0,0.2);
			box-shadow: $boxShadowHover;
		}

		@include transitions {
			transition: box-shadow 0.2s;
		}
	}

	+ .lit-input {
		margin-top: $space-sm;
	}

	&.inline {
		display: flex;
		align-items: center;

		.lit-input {
			&__field,
			&__label {
				display: inline-block;
				vertical-align: middle;
			}

			&__field {
				width: auto;
				flex-grow: 1;
				height: 40px;
			}

			&__label {
				flex-shrink: 0;
				min-width: 80px;
				margin-bottom: 0;
				margin-right: $space-sm;

				&.tiny {
					min-width: 30px;
				}

				&.wide {
					min-width: 150px;
				}
			}
		}

		.lit-btn {
			height: 100%;
			margin-left: $space-xsm;
			height: 40px;
			margin-top: 0;
		}

		@include mobileThin {
			display: block;

			.lit-input {
				&__label {
					margin-bottom: $space-xsm;
				}

				&__field {
					width: 100%;
				}
			}
		}
	}

	@include transitions {
		// @include stagger;
	}
}

.lit-toggle {
	$selectorWidth: 45px;
	$selectorHeight: $selectorWidth * 2;
	$selectorBg: #2b2533;
	font-size: 0;

	&__input {
		display: none;
	}

	&__label {
		display: inline-block;
		position: relative;
		line-height: $selectorHeight;
		width: $selectorWidth;
		margin: $space-sm 0;
		cursor: pointer;

		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
			left: 0;
		}

		&:before {
			width: $selectorWidth;
			height: $selectorHeight;
			background-color: #2b2533;
			border-radius: $borderRadius;
			// box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.06);
			transition: background-color 0.3s;
		}

		&:after {
			$offset: 4px;
			width: $selectorWidth;
			height: $selectorHeight / 2;
			top: 0;
			left: 0;
			// border-radius: 50%;
			background-color: $primary;
			box-shadow: $boxShadow1;
			transition: transform 0.3s, border-radius 0.3s;
			border-radius: $borderRadius $borderRadius 0 0;
		}

		@include transitions {
			&:hover {
				&:before {
					transition: background-color 0.3s;
					background-color: lighten($selectorBg, 8%);
				}
			}
		}

		&:active {
			color: lighten($textColor, 15%);

			&:before {
				transition: none;
				background-color: lighten($selectorBg, 15%);
			}
		}
	}

	&__ico {
		&--off,
		&--on {
			position: absolute;
			left: 0;
			z-index: 1;
		}

		&--on {
			top: $selectorHeight / 2;
		}
	}

	.lit-toggle__input:checked + .lit-toggle__label {
		&:before {
			background-color: #2b2533;
		}

		&:after {
			transform: translateY(-($selectorWidth - $selectorHeight));
			transform: translate3d(0, -($selectorWidth - $selectorHeight), 0);
			border-radius: 0 0 $borderRadius $borderRadius;
		}

		@include transitions {
			&:hover {
				&:before {
					transition: background-color 0.3s;
					background-color: lighten($selectorBg, 8%);
				}
			}
		}

		&:active {
			&:before {
				transition: none;
				background-color: lighten($selectorBg, 15%);
			}
		}
	}

	@include mobile {
		font-size: $small;
	}
}
