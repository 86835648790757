.lit-tests-view {
	.month {
		margin-top: 40px;

		&__heading {
			text-transform: capitalize;
			margin-bottom: 20px;
			font-family: 'Lato';
			font-weight: bold;
			color: black;
			text-transform: uppercase;
		}
	}
}

.lit-tests {
	width: 100%;
	border-spacing: 0;
	border-bottom: 3px solid $white;
	border-radius: 10px;
	border-top: 3px solid $cerulean;
	background-color: $cerulean;
	margin-top: 5px;
}

.lit-test {
	&-no-margin {
		margin: 0;
	}

	&__header {
		padding: 10px 5px;
		color: $white;
		width: 1000px;
	}

	&__col {
		padding: 5px;
		background: $white;
	}

	// background-color: $white;
	// background-color: rgba(255, 255, 255, 0.5);
	// border: 2px solid $white;
	// padding: $space $space $space-m;
	// box-shadow: $boxShadow1;
	// margin: $space-sm $space-sm;
	// display: inline-block;
	// vertical-align: top;
	// width: 300px;
	// max-width: 100%;
	// min-height: 450px;
	// border-radius: $borderRadius;

	// @include transitions {
	// 	@include stagger(fade, 8);
	// }

	// &__col {
	// 	display: block;
	// 	// padding: $space-xsm 0;

	// 	&:last-of-type {
	// 		border-bottom: none;
	// 	}
	// }

	// &__field {
	// 	// background-color: $white;
	// 	// box-shadow: $boxShadow2;
	// 	padding: $space-sm;

	// 	p {
	// 		margin: 0;
	// 		font-size: $small;
	// 	}
	// }

	// &__heading {
	// 	margin: 0;
	// 	text-transform: uppercase;
	// 	font-size: $tiny;
	// 	letter-spacing: 0.03em;
	// 	color: $mediumGray;
	// }

	// .lit-btn {
	// 	margin-top: $space-xsm;
	// }
}

// .lit-test-email {
// 	margin: (-$space) (-$space) $space;
// 	padding: $space $space-m;
// 	background-color: rgba(0, 0, 0, 0.04);
// 	font-weight: bold;
// 	// text-align: center;
// 	border-radius: $borderRadius;

// 	&.started,
// 	&.completed {
// 		background: linear-gradient(to right, #b3f7c8, #a3fbeb);
// 		box-shadow: rgba(0, 0, 0, 0.04) 0px 8px 13px;
// 	}

// 	.id {
// 		font-size: $tiny;
// 		opacity: 0.5;
// 		font-weight: normal;
// 	}
// }

// .lit-test-url {
// 	margin: $space-sm 0 $space-sm;
// 	.lit-test__heading {
// 		margin-left: ($space-sm);
// 	}

// 	&__input {
// 		border: none;
// 		padding: $space $space-sm;
// 		background-color: $white;
// 		// background-color: #f1f1f1;
// 		box-shadow: $boxShadow4;
// 		border-radius: 3px;
// 		width: 100%;
// 	}
// }

.lit-test-status {
	// margin-top: -($space-lg);

	&__indicator {
		width: 25px;
		height: 25px;
		display: inline-block;
		vertical-align: middle;
		background-color: $mediumGray;
		border-radius: 50%;
		box-shadow: $boxShadow3;

		&.started {
			background-color: $orange;
		}

		&.distributed {
			background-color: $purple;
		}

		&.completed,
		&.active {
			background-color: $positiveColor;
		}

		&.all {
			background-color: transparent;
			width: 0;
			box-shadow: none;
		}
	}

	&__label {
		display: inline-block;
		vertical-align: middle;
		margin: 0 0 0 $space-sm;
		font-size: 14px;
		letter-spacing: 0.03em;
		color: $gray;
	}

	&__margin {
		display: inline-block;
		vertical-align: middle;
		margin: -2 0 0 $space-sm;
		text-transform: capitalize;
		font-size: 12px;
		letter-spacing: 0.03em;
		color: $gray;
	}
}
