@import 'vars/colors';
@import 'vars/spacing';

.oiw-box {
	box-sizing: border-box;

	&--full-height {
		height: 500px;
		height: 100vh;
	}

	&--border {
		// border: 1px solid;
	}

	&--left {
		// border-top: 1px solid;
		// border-bottom: 1px solid;
		// border-right: 1px solid;
	}

	&--right {
		// border-top: 1px solid;
		// border-bottom: 1px solid;
		// border-left: 1px solid;
	}

	&__content {
		padding: $contentMargin;
	}

	&__centerer {
		display: table;
		width: 100%;
		height: 100%;
		text-align: center;

		> .oiw-box__content {
			display: table-cell;
			vertical-align: middle;
		}
	}

	&__bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		&--fader {
			opacity: 0;
			animation: oiwSoftFade 4s linear alternate infinite;

			@media (max-width: 1500px) {
				display: none;
			}
		}
	}
}
