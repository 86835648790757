.lit-view {
	//margin-top: $headerHeight;
	margin-left: $sidebarWidth;
	//box-shadow: $boxShadow2;
	border-radius: 25px 0px 0px 0px;
	background-color: $cyan;
	position: relative;
	z-index: ($zSidebar + 1);
	padding: 10px 0px 0px 10px;

	&__content {
		min-height: calc(100vh - #{$headerHeight});
		padding: 30px 0px 20px 80px;
		background-color: $skyBlue;
		border-radius: 35px 0px 0px 0px;
	}
}
