@import 'vars/typography';
@import 'vars/breakpoints';

.lit-sans-serif {
	font-family: $sansSerif;
}
.lit-serif {
	font-family: $serif;
}

body {
	font-family: $fontFamily;
	color: $textColor;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0 0 $space;

	+ p {
		margin-top: 0;
	}
}

h1,
.lit-h1 {
	@include h1;
}

h2,
.lit-h2 {
	@include h2;
}

h3,
.lit-h3 {
	@include h3;
}

h4,
.lit-h4 {
	@include h4;
}

p {
	line-height: 1.4;
	@include p;
}

.capitalize {
	text-transform: capitalize;
}
