.notification-list {
	margin: 20px auto;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	font-family: Arial, sans-serif;
	background-color: #f0f9fc;
	border-radius: 25px 0 0 0;
	margin-left: -65px;
	padding: 10px;
	margin-top: -1px;
}
