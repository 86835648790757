/* TRANSITIONS BREAKPOINT:
Hover states won't be added for devices smaller than this width in order to not require two clicks on iOS before action. */
$breakpointTransitions: 1025;
$transitions: '(min-width: #{$breakpointTransitions}px)';
@mixin transitions {
	@media (min-width: #{$breakpointTransitions}px) {
		@content;
	}
}

/* TABLET */
$breakpointTablet: 1024;
$tablet: '(max-width: #{$breakpointTablet}px)';
@mixin tablet {
	@media (max-width: #{$breakpointTablet}px) {
		@content;
	}
}

/* Reverse menu collapse */
$breakpointMenuCollapse: 1140;
$menuExpand: '(min-width: #{$breakpointMenuCollapse}px)';
@mixin menuExpand {
	@media (min-width: #{$breakpointMenuCollapse}px) {
		@content;
	}
}

/* Menu collapse */
$menuCollapse: '(max-width: #{$breakpointMenuCollapse}px)';
@mixin menuCollapse {
	@media (max-width: #{$breakpointMenuCollapse}px) {
		@content;
	}
}

/* Flexbox collumns collapse */
$breakpointFlexCollapse: 1140;
$flexCollapse: '(max-width: #{$breakpointFlexCollapse}px)';
@mixin flexCollapse {
	@media (max-width: #{$breakpointFlexCollapse}px) {
		@content;
	}
}

/* MOBILE */
$breakpointMobileWide: 940;
$mobileWide: '(max-width: #{$breakpointMobileWide}px)';
@mixin mobileWide {
	@media (max-width: #{$breakpointMobileWide}px) {
		@content;
	}
}
$breakpointMobile: 600;
$mobile: '(max-width: #{$breakpointMobile}px)';
@mixin mobile {
	@media (max-width: #{$breakpointMobile}px) {
		@content;
	}
}
$breakpointMobileThin: 440;
$mobileThin: '(max-width: #{$breakpointMobileThin}px)';
@mixin mobileThin {
	@media (max-width: #{$breakpointMobileThin}px) {
		@content;
	}
}
$breakpointMobileTiny: 350;
$mobileTiny: '(max-width: #{$breakpointMobileTiny}px)';
@mixin mobileTiny {
	@media (max-width: #{$breakpointMobileTiny}px) {
		@content;
	}
}

$breakpointWordpressMobile: 782;
$mobileWordpressMobile: '(max-width: #{$breakpointWordpressMobile}px)';
@mixin wordpressMobile {
	@media (max-width: #{$breakpointWordpressMobile}px) {
		@content;
	}
}

@mixin print {
	@media print {
		@content;
	}
}
